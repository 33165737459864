/** @jsx jsx */
import { jsx, Styled, useColorMode } from "theme-ui"

import { Link, useStaticQuery, graphql } from "gatsby"

import { useState, useEffect } from "react"
import { Transition } from "react-spring/renderprops"

const Burger = ({ show }) => {
  return (
    <Transition
      items={show}
      from={{ display: "none" }}
      enter={{ display: "block" }}
      leave={{ display: "none" }}
    >
      {show => (show ? props => "Close" : props => "Info")}
    </Transition>
  )
}

const Menu = ({ show }) => {
  const { allPrismicAbout } = useStaticQuery(
    graphql`
      query {
        allPrismicAbout {
          nodes {
            data {
              title {
                text
              }
              text {
                text
                html
              }
              instagram {
                url
              }
              email {
                url
              }
            }
          }
        }
      }
    `
  )

  const about = allPrismicAbout.nodes[0].data

  return (
    <Transition
      items={show}
      config={{ duration: 100 }}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {show =>
        show &&
        (props => (
          <div
            style={{
              ...props,
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <div
              sx={{
                m: "0 auto",
                pt: 0,
                height: "100%",
                overflow: "scroll",
                backgroundColor: "background",
              }}
            >
              <div
                sx={{
                  m: "0 auto",
                  p: 4,
                  pt: "200px",
                  maxWidth: "640px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: about.text.text }}
                  sx={{ color: "text", fontSize: 3, fontWeight: "body", mb: 3 }}
                />
                <div sx={{ mt: 2 }}>
                  <Styled.h5
                    as="a"
                    sx={{
                      textDecoration: "none",
                      borderBottom: "none",
                      fontSize: 3,
                      mr: 3,
                    }}
                    href={about.email.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Email
                  </Styled.h5>
                  <Styled.h5
                    as="a"
                    sx={{
                      textDecoration: "none",
                      borderBottom: "none",
                      fontSize: 3,
                    }}
                    href={about.instagram.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </Styled.h5>
                </div>
                <p sx={{ fontSize: 2 }}>
                  Website by&nbsp;
                  <a
                    sx={{ fontSize: 2, color: "text", textDecoration: "none" }}
                    href="https://jahlherapath.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jahl Herapath
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))
      }
    </Transition>
  )
}

function Header() {
  const [colorMode, setColorMode] = useColorMode()
  const [showMenu, setShowMenu] = useState(false)

  const handler = () => setShowMenu(false)

  useEffect(() => {
    window.addEventListener("scroll", handler)
    window.addEventListener("resize", handler)
    return () => {
      window.removeEventListener("scroll", handler)
      window.removeEventListener("resize", handler)
    }
  }, [])

  return (
    <header
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
    >
      <div
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          py: 4,
        }}
      >
        <Menu show={showMenu} />
        <Link
          to="/"
          sx={{
            fontSize: ["36px", "36px", 6],
            fontFamily: "heading",
            fontWeight: "heading",
            color: "text",
            pb: 2,
            textAlign: "center",
            border: "none",
            zIndex: 10,
            textDecoration: "none",
            lineHeight: [1, 1, 1],
          }}
        >
          Matthew Moran
        </Link>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            zIndex: 10,
          }}
        >
          <Styled.h5
            as="p"
            sx={{
              mt: 0,
              mr: 3,
              borderBottom: "none",
              fontSize: 3,
              color: "gray",
              cursor: "pointer",
            }}
            onClick={() => setShowMenu(x => !x)}
          >
            <Burger show={showMenu} />
          </Styled.h5>
          <div
            sx={{
              position: "relative",
              display: "inline-flex",
              width: "51px",
              height: "26px",
              borderRadius: "15px",
              backgroundColor: "text",
              cursor: "pointer",
            }}
            onClick={e => {
              setColorMode(colorMode === "default" ? "dark" : "default")
            }}
          >
            <div
              sx={{
                position: "absolute",
                top: "50%",
                left: colorMode === "default" ? "13px" : "38px",
                transformOrigin: "center",
                transform: "translate(-50%,-50%)",
                width: "20px",
                height: "20px",
                border: "2px solid",
                borderColor: "background",
                borderRadius: "50%",
                backgroundColor: "background",
                transition: "200ms ease-in-out",
              }}
            ></div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
