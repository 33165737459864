/** @jsx jsx */
import { jsx } from "theme-ui"

import React from "react"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import GlobalStyles from "../GlobalStyles/GlobalStyles"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: `100vh`,
          backgroundColor: "background",
          maxWidth: "1280px",
          m: "0 auto",
        }}
      >
        <GlobalStyles />
        <Header />
        <main sx={{ mt: ["80px", "80px", "100px"], mb: 4, p: 4, pt: 5 }}>
          {children}
        </main>
        <Footer />
      </div>
    )
  }
}

export default Layout
