/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import Fade from "react-reveal/Fade"

function Footer(props) {
  return (
    <footer sx={{ p: 4, textAlign: "center" }}>
      <Fade bottom>
        <Styled.h3
          sx={{
            m: 0,
            fontWeight: "heading",
            fontFamily: "mono",
            fontSize: 3,
            color: "gray",
          }}
        >
          {new Date().getFullYear()} — Sydney, Australia
        </Styled.h3>
      </Fade>
    </footer>
  )
}

export default Footer
