/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"

import { Link } from "gatsby"
import Img from "gatsby-image"

import Categories from "./Categories"

function Card({ node, categories }) {
  return (
    <Box
      sx={{
        gridColumn: ["span 12", "span 6", "span 6"],
        "&:nth-of-type(1)": {
          gridColumn: ["span 12", "span 6", "span 12"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(2)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(3)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(4)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(5)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(6)": {
          gridColumn: ["span 12", "span 6", "span 10"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(7)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(8)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(9)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(10)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(11)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(12)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(13)": {
          gridColumn: ["span 12", "span 6", "span 10"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(14)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(15)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(16)": {
          gridColumn: ["span 12", "span 6", "span 10"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(17)": {
          gridColumn: ["span 12", "span 6", "span 12"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(18)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(19)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(20)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(21)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(22)": {
          gridColumn: ["span 12", "span 6", "span 10"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(23)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(24)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(25)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(26)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(27)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(28)": {
          gridColumn: ["span 12", "span 6", "span 6"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(29)": {
          gridColumn: ["span 12", "span 6", "span 10"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(30)": {
          gridColumn: ["span 12", "span 6", "span 8"],
          mb: [4, 4, 5],
        },
        "&:nth-of-type(31)": {
          gridColumn: ["span 12", "span 6", "span 4"],
          mb: [4, 4, 0],
        },
        "&:nth-of-type(32)": {
          gridColumn: ["span 12", "span 6", "span 10"],
          mb: [4, 4, 0],
        },
      }}
    >
      <Link
        to={`/${node.uid}`}
        sx={{
          textDecoration: "none",
          color: "text",
          transition: "all 250ms cubic-bezier(.02, .01, .47, 1)",
          "&:hover": {
            color: "primary",
          },
          "&:hover > h3": {
            opacity: 0.6,
          },
        }}
      >
        {node.data.thumbnail && (
          <Img fluid={node.data.thumbnail.localFile.childImageSharp.fluid} />
        )}
        <Styled.h2>{node.data.title.text}</Styled.h2>
        <Styled.h3
          sx={{
            color: "gray",
            transition: "all 250ms cubic-bezier(.02, .01, .47, 1)",
            fontWeight: "heading",
            fontFamily: "mono",
          }}
        >
          {node.data.quote.text}
        </Styled.h3>
      </Link>
      {categories && (
        <div>
          <Categories categories={categories} />
        </div>
      )}
    </Box>
  )
}

export default Card
