/** @jsx jsx */
import { jsx } from "theme-ui"

import { Global } from "@emotion/core"

import Cursor1 from "../../assets/cursor@1x.png"
import Cursor2 from "../../assets/cursor@2x.png"

const GlobalStyles = () => {
  return (
    <div>
      <Global
        styles={theme => ({
          html: {
            margin: 0,
            padding: 0,
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            textRendering: "optimizeLegibility",
          },
          a: {
            color: theme.colors.primary,
            "&:hover": {
              opacity: 0.8,
            },
          },
          "a, body": {
            cursor: `-webkit-image-set(url(${Cursor1}) 1x, url(${Cursor2}) 2x) 16 16, pointer !important`,
          },
        })}
      />
    </div>
  )
}

export default GlobalStyles
