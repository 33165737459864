/** @jsx jsx */
import { jsx } from "theme-ui"

import { Link } from "gatsby"

import kebabCase from "lodash/kebabCase"

function Categories({ categories }) {
  return (
    <div sx={{ mb: 4 }}>
      {categories &&
        categories.map((cat, i) => (
          <div
            key={cat + i}
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              height: "38px",
              borderRadius: "40px",
              backgroundColor: "primary",
              fontSize: 2,
              fontWeight: "heading",
              fontFamily: "mono",
              px: "22px",
              my: 3,
              mr: 3,
              transition: "all 250ms cubic-bezier(.02, .01, .47, 1)",
              "&:hover": {
                opacity: 0.8,
              },
            }}
          >
            <Link
              sx={{ color: "white", textDecoration: "none" }}
              to={`/categories/${kebabCase(cat)}`}
            >
              {cat}
            </Link>
          </div>
        ))}
    </div>
  )
}

export default Categories
